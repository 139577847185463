<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  My tickets
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ My tickets
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.date_from"
                          label="View from date"
                          prepend-icon=""
                          prepend-inner-icon="mdi-calendar"
                          v-on:keyup.enter="searchSupportTicket()"
                          @input="search.date_from = $event !== null ? $event : ''"
                          :error="checkDateFrom"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.date_from"
                        no-title
                        @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                  <!--                  <span v-if="checkDateFrom" class="text-danger">This information is required !!</span>-->
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.date_to"
                          label="View up to date"
                          prepend-icon=""
                          prepend-inner-icon="mdi-calendar"
                          v-on:keyup.enter="searchSupportTicket()"
                          :error="checkDateTo"
                          @input="search.date_to = $event !== null ? $event : ''"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.date_to"
                        no-title
                        @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu><br>
                  <!--                  <span v-if="checkDateTo" class="text-danger">This information is required !!</span>-->
                </v-col>

                <!--                <v-col cols="12" sm="6" md="2">-->
                <!--                  <v-text-field-->
                <!--                      label="Request"-->
                <!--                      v-model="search.name"-->
                <!--                      outlined-->
                <!--                      dense-->
                <!--                      @input="search.name = $event !== null ? $event : ''"-->
                <!--                      clearable-->
                <!--                      v-on:keyup.enter="searchSupportTicket()"-->
                <!--                  ></v-text-field>-->
                <!--                </v-col>-->
                <!--                <v-col cols="12" sm="6" md="2">-->
                <!--                  <v-select-->
                <!--                      label="Type"-->
                <!--                      v-model="search.type"-->
                <!--                      :items="types"-->
                <!--                      item-text="name"-->
                <!--                      item-value="value"-->
                <!--                      outlined-->
                <!--                      dense-->
                <!--                      @input="search.type = $event !== null ? $event : ''"-->
                <!--                      v-on:keyup.enter="searchSupportTicket()"-->
                <!--                      clearable-->
                <!--                  ></v-select>-->
                <!--                </v-col>-->

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Search by email, name or phone"
                      v-model="search.user_info"
                      outlined
                      dense
                      @input="search.user_info = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchSupportTicket()"
                      clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Enrolment key"
                      v-model="search.enrol_key"
                      outlined
                      dense
                      @input="search.enrol_key = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchSupportTicket()"
                      clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2" v-if="currentUser.access_type == 'federal'">
                  <v-select
                      label="SCORE"
                      v-model="search.score_id"
                      :items="scores"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      @input="search.score_id = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchSupportTicket()"
                      clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-select
                      label="Admin activity type"
                      v-model="search.is_read"
                      :items="read_types"
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                      @input="search.is_read = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchSupportTicket()"
                      clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Assigned to"
                      v-model="search.assignedTo"
                      outlined
                      dense
                      @input="search.assignedTo = $event !== null ? $event : ''"
                      clearable
                      v-on:keyup.enter="searchSupportTicket()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Assigned by"
                      v-model="search.assignedBy"
                      outlined
                      dense
                      @input="search.assignedBy = $event !== null ? $event : ''"
                      clearable
                      v-on:keyup.enter="searchSupportTicket()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-select
                      v-model="search.assigned_type"
                      :items="assignedTypes"
                      label="Access type"
                      item-text="name"
                      item-value="value"
                      outlined
                      clearable
                      v-on:keyup.enter="searchSupportTicket()"
                      dense
                  ></v-select>
                </v-col>

                <!--                <v-col cols="12" sm="6" md="2">-->
                <!--                  <v-menu-->
                <!--                      ref="menu"-->
                <!--                      v-model="menu2"-->
                <!--                      :close-on-content-click="false"-->
                <!--                      transition="scale-transition"-->
                <!--                      offset-y-->
                <!--                      min-width="auto"-->
                <!--                  >-->
                <!--                    <template v-slot:activator="{ on, attrs }">-->
                <!--                      <v-text-field-->
                <!--                          v-model="search.assigned_date"-->
                <!--                          label="Assigned date"-->
                <!--                          readonly-->
                <!--                          outlined-->
                <!--                          dense-->
                <!--                          clearable-->
                <!--                          v-bind="attrs"-->
                <!--                          v-on="on"-->
                <!--                      ></v-text-field>-->
                <!--                    </template>-->
                <!--                    <v-date-picker-->
                <!--                        v-model="search.assigned_date"-->
                <!--                        outlined-->
                <!--                        dense-->
                <!--                        no-title-->
                <!--                        @input="menu2 = false"-->
                <!--                    >-->
                <!--                    </v-date-picker>-->
                <!--                  </v-menu>-->
                <!--                </v-col>-->

                <v-col cols="12" md="2">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      clearable
                      v-on:keyup.enter="searchSupportTicket()"
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-btn
                      @click="searchSupportTicket()"
                      class="btn btn-block btn-primary"
                      style="color: #fff"
                      :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>

            <div class="table-responsive">

              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Request</th>
                  <th class="px-3">Summary</th>
                  <th class="px-3">Requested by</th>
                  <th class="px-3">Managed by</th>
                  <th class="px-3">Added date</th>
                  <th class="px-3">Status</th>
                  <th class="px-3">Options</th>
                </tr>
                </thead>
                <tbody class="mt-5">
                <template>
                  <tr v-bind:class="{ 'ameb__bg-light-blue': !item.is_read_admin }" v-for="(item, index) in support_tickets" :key="index">
                    <td class="px-2">
                      <a @click="viewSupportThread(item)" class="d-flex align-items-center">
                        <p class="tfont-weight-bolder">{{ item.name }}</p>
                      </a>
                    </td>
                    <td class="px-3">
                      <p> {{item.category_name }}</p>
                      <span class="font-size-sm badge badge-success"> {{item.score }}</span>
                    </td>
                    <td class="px-3">
                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                       <b>Enroller: </b> <a class="mx-3 text-primary" @click="goToUser(item)"> {{item.user ? item.user : 'NA' }}</a>
                      </span>
                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        <b>Candidate: </b> <a class="mx-3 text-primary" @click="goToCandidate(item.candidate_exam_key)">{{item.candidate ? item.candidate : 'NA' }}</a>
                      </span>
                    </td>
                    <td class="px-3" v-if="item.assigned_type == 'score'">
                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        <b class="mr-3" v-if="item.assignedTo">Assigned to: </b> {{item.assignedTo ? item.assignedTo : '' }}
                      </span>
                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        <b class="mr-3" v-if="item.assignedBy">Assigned by: </b> {{item.assignedBy ? item.assignedBy : '' }}
                      </span>
                      <span class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        <b class="mr-3" v-if="item.formatted_assigned_date">Assigned date: </b> {{item.formatted_assigned_date ? item.formatted_assigned_date : '' }}
                      </span>
                    </td>
                    <td v-else>
                      <span>NA</span>
                    </td>
                    <td class="px-3">
                      <p  class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        {{ item.formatted_created_at ? item.formatted_created_at : '--'}}
                      </p>
                      <span class="fw-bold">{{item.readable_created_at}}</span>
                    </td>
                    <td class="px-3">
                     <span class="badge"
                           v-bind:class="{ 'badge-success': item.ticket_status == 'In progress', 'badge-danger': item.ticket_status != 'In progress'}"
                     >{{ item.ticket_status }}</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="assignedTo(item)" class="navi-link">
                                  <span class="navi-icon">
                                    <i class="fa fa-user"></i>

                                  </span>
                                <span class="navi-text">Assign Ticket</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="item.assigned_type == 'score'">
                              <a @click="unAssignedTicket(item.id)" class="navi-link">
                                  <span class="navi-icon">
                                       <i class="fa fa-user-times"></i>
                                  </span>
                                <span class="navi-text">Unassign Ticket</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="item.type_text=='Refund' && item.assigned_to && item.assigned_by && !item.is_approved && !item.is_rejected">
                              <a @click="manageRefund(item.id,item.is_refunded)" class="navi-link">
                                  <span class="navi-icon">
                                     <i class="fas fas fa-tasks"></i>

                                  </span>
                                <span class="navi-text">Approve/ Not approve</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="support_tickets.length == 0">
                    <td colspan="10" class="text-center">
                      <strong>No tickets available</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="support_tickets.length > 0"
                  class="pull-right mt-7"
                  @input="getAllSupportTicket"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <assigned-to ref="assigned-to" @refresh="getAllSupportTicket"></assigned-to>
    </div>
  </v-app>
</template>
<script>
import SupportTicketService from "@/services/support/support-ticket/SupportTicketService";
import ScoreService from "@/services/score/score/ScoreService";
import AssignedTo from "@/view/pages/view/support/support-ticket/assigned/AssignedTo";
import {SET_SUPPORT} from "@/core/services/store/support.module";

const supportTicket = new SupportTicketService();
const score = new ScoreService();
export default {
  components: {AssignedTo},
  data() {
    return {
      support_tickets: [],
      total: null,
      perPage: null,
      page: null,
      loading: false,
      menu: false,
      menu1: false,
      menu2: false,
      isLoading: false,
      scores: [],
      currentUser: {},
      search:{
        date_to: '',
        date_from: '',
        user_info: '',
        enrol_key: '',
        name: '',
        type: '',
        is_read: '',
        score_id: '',
        assignedTo: '',
        assignedBy: '',
        assigned_date: '',
        assigned_type: '',
        is_active: '',
      },
      assignedTypes: [
        {name: 'Federal', value: 'federal'},
        {name: 'Score', value: 'score'}
      ],
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      read_types: [
        {name: 'Read Ticket', value: '1'},
        {name: 'UnRead Ticket', value: '0'}
      ],
      types: [
        {name: 'General', value: 'general'},
        {name: 'Extension', value: 'extension'},
        {name: 'Refund', value: 'refund'},
        {name: 'Payment', value: 'payment'},
        {name: 'Appeal', value: 'appeal'},
        {name: 'Feedback', value: 'feedback'},
        {name: 'Shipment', value: 'shipment'},
        {name: 'Other', value: 'other'},
      ],
    }
  },
  mounted() {
    this.getAllSupportTicket();
    this.getAllScore();
    this.getCurrentUser();
  },
  computed: {
    checkDateFrom() {
      return this.search.date_to != '' && this.search.date_from == '';
    },
    checkDateTo() {
      return this.search.date_from != '' && this.search.date_to == '';
    }
  },
  methods:{
    getAllSupportTicket(){
      this.loading =  true;
      supportTicket
          .getAssignedTicket(this.search,this.page)
          .then(response => {
            this.support_tickets=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading =  false;
            this.$store.dispatch(SET_SUPPORT);
          })
          .catch((err) => {});
    },

    viewSupportThread(ticket){
      if(!ticket.is_read_admin) {
        ticket.is_read_admin = true;
        supportTicket.update(ticket.id, ticket).then(response =>{
          this.getAllSupportTicket();
        }).catch((err) => {
          this.$snotify.error("Oops something went wrong");
        });
      }
      this.$router.push({
        name:'support-ticket-view',
        params:{supportTicketId: ticket.id}
      });
    },

    searchSupportTicket(){
      this.getAllSupportTicket();
    },

    getAllScore() {
      score.all().then(response => {
        this.scores = response.data.scores;
      }).catch((err) => {
        this.$snotify.error("Oops something went wrong");
      });
    },

    assignedTo(supportTicket) {
      this.$refs['assigned-to'].assignedTo(supportTicket)
    },

    unAssignedTicket(supportTicketId) {
      this.$confirm({
        message: `Are you sure ? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            supportTicket.unassignedTicket(supportTicketId).then(response => {
              this.getAllSupportTicket();
            }).catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
          }
        },
      });
    },

    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
  }
}
</script>